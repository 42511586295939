import * as React from "react";
import {useEffect, useState} from "react";
import Lottie from 'react-lottie-player';
import * as scrollAnimationData from '../lib/scrollindicator-animation.json'

const ScrollIndicator = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const onScroll = (e) => {
            setIsVisible(window.scrollY < 100)
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <>
            <div className={`c-scroll-indicator ${!isVisible ? 'hidden' : ''} u-hidden@lap`}>
                <div className={"c-scroll-indicator--animation"}>
                    <Lottie
                        loop={true}
                        animationData={scrollAnimationData}
                        play={true}
                        style={{ width: 100, height: 100, alignSelf: 'center' }}
                    />
                </div>
            </div>
        </>
    )
};

export default ScrollIndicator;
